import * as React from 'react';

import {SanityTopic} from 'types/generated';

import TopicTemplate from './index';

export default ({
  pageContext,
}: //   location,
{
  pageContext: {
    topic: SanityTopic;
    articles: [any];
    categories: [any];
    numPages: number;
    currentPage: number;
    limit: number;
  };
  //   location: Location;
}): React.ReactElement => (
  <TopicTemplate
    topic={pageContext.topic}
    articles={pageContext.articles}
    currentPage={pageContext.currentPage}
    limit={pageContext.limit}
    numPages={pageContext.numPages}
  />
);
